<template>
  <div class="change_bg">

    <Navbar></Navbar>
    <section>
      <div class="container">
        <div class="manage_link">
          <div class="m_link1">
            <router-link to="/">
              {{$store.state.language.lang[1]}}</router-link>
          </div>
          <div>
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0.106862 0.191111C0.277469 -0.0260253 0.591796 -0.0637446 0.808932 0.106862L7.80893 5.60686C7.92958 5.70165 8.00002 5.84659 8.00002 6.00002C8.00002 6.15345 7.92958 6.29839 7.80893 6.39318L0.808932 11.8932C0.591796 12.0638 0.277469 12.0261 0.106862 11.8089C-0.0637446 11.5918 -0.0260253 11.2775 0.191111 11.1069L6.69073 6.00002L0.191111 0.89318C-0.0260253 0.722574 -0.0637446 0.408246 0.106862 0.191111Z"
                    fill="#89899F"/>
            </svg>
          </div>
          <div class="m_link2">
            <router-link to="/">

              {{$store.state.language.lang[75]}}
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-3 col-12">
            <div class="m_sidebar">
              <div class="m_sidebar_title">
                {{$store.state.language.lang[3]}}
              </div>
              <div class="m_sidebar_link">
                <router-link to="/news">
                  {{$store.state.language.lang[69]}}</router-link>
              </div>

              <div class="m_sidebar_link">
                <router-link to="/press_secretary">
                  {{$store.state.language.lang[71]}}</router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/press_releases">
                  {{$store.state.language.lang[72]}}</router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/tenders">
                  {{$store.state.language.lang[74]}}</router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/media">
                  {{$store.state.language.lang[70]}}</router-link>
              </div>
            </div>

          </div>
          <div class="col-xl-9 col-12" >
            <div class="m_body_title">
              <h2>
                {{$store.state.language.lang[75]}}</h2>
            </div>
            <MediaSideNews :name="$store.state.language.lang[69]"></MediaSideNews>
            <div class="main_media">
              <div class="media_btn">
                <button :class="{'active':isMedia}" @click="isMedia=true">
                  {{$store.state.language.lang[70]}}</button>
                <button :class="{'active':!isMedia}" @click="isMedia=false">
                  {{$store.state.language.lang[68]}}</button>
              </div>
              <div class="photos" v-if="isMedia">
                <div >
                  <gallery :images="$store.state.images" :index="index" @close="index = null"></gallery>
<!--                  <div-->
<!--                      class="image"-->
<!--                      v-for="(image, imageIndex) in images"-->
<!--                      :key="imageIndex"-->
<!--                      @click="index = imageIndex"-->
<!--                      :style="{ backgroundImage: 'url(' + image + ')', width: '300px', height: '200px' }"-->
<!--                  ></div>-->
                </div>
                <div class="row">
                  <div class="col-xl-4 col-md-6 col-12 main_media_img d-inline"
                       v-for="(image, imageIndex) in $store.state.images" :key="imageIndex">
                    <div>
                      <div   @click="index = imageIndex" class="links">
                        <div class="photos_card">
                          <img :src="image" :alt="image">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="videos" v-if="!isMedia">
                <div class="row">
                  <div class="col-12  video_main"   v-if="$store.state.media2!=''">
                    <LazyYoutube

                        ref="youtubeLazyVideo"
                        :src="$store.state.media2"
                        max-width="100%"
                        aspect-ratio="16:9"
                        thumbnail-quality="standard"
                    />
                  </div>
                  <div class="col-12 col-xl-6 video_father" v-for="(videos,indexx) in $store.state.media.results" :key="indexx">
                    <LazyYoutube
                        v-if="videos.url!=''"
                        ref="youtubeLazyVideo"
                        :src="videos.url"
                        max-width="100%"
                        aspect-ratio="16:9"
                        thumbnail-quality="standard"
                        max-height="300px"
                    />
                    <div class="btn_child">
                      <div class="btn_child_1">
                        <button @click="myvideos(videos.url)">
                          <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.8889 13.7573L3.33333 23.5983C3.11111 23.7991 2.66667 24 2.22223 24C1.77778 24 1.55556 24 1.11111 23.7992C0.444444 23.3975 0 22.795 0 21.9916V2.10877C0 1.30543 0.444444 0.702929 1.11111 0.301255C1.77778 -0.100418 2.66667 -0.100418 3.33333 0.301255L20.8889 10.1422C21.5556 10.5439 22 11.1464 22 11.7489C22 12.3515 21.5556 13.3556 20.8889 13.7573Z" fill="#01011C"/>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>
<script>
import VueGallery from 'vue-gallery';
import { LazyYoutube} from "vue-lazytube";


import Navbar from "../Header/Navbar";
import Footer from "../Header/Footer";
import MediaSideNews from "../MediaSideNews";
export default {
  components: {
    'gallery': VueGallery,
    Footer,
    Navbar,
    MediaSideNews,
    LazyYoutube,

  },
  data() {
    return {
      youtubeLink:'',
      isActive_m: false,
      isMedia: true,
      youtube_link: 'https://youtu.be/OEhMA1DBi0A',
      videoId: 'OEhMA1DBi0A',
      images: [],
      index: null
    }
  },
  mounted() {
    this.$store.dispatch('media')

    document.querySelector('.ytp-title-channel-logo').style.background="none"

  },
  methods: {
    open_modal() {

    },
    myvideos(item){
      this.$store.state.media2=item
    }
  }
}
</script>
<style scoped>
.ytp-title-channel{
  z-index:  0 !important;
  right: -30px !important;
}
.video_main{
  background: #efefef;
  padding: 20px;
  margin-bottom: 20px;
}
</style>
